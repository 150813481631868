export default {
  authPath: '/users/sign_in',
  appleAuthPath: '/users/auth/apple',
  googleAuthPath: '/users/auth/google_oauth2',
  signUp: '/users',
  signOut: '/users/sign_out',
  editUser: '/users',
  resetPassword: '/users/password',
  setPassword: '/users/invitation',
  autocompletes: text => `/address_autocompletes.json?term=${encodeURIComponent(text)}`,
  saveSearch: '/saved_searches',
  saveListing: '/saved_listings',
  destroySearch: id => `/saved_searches/${id}`,
  contactAgent: '/contact_agent',
  contactOwner: '/contact_owner',
  genericContactAgent: '/generic_contacts',
  shareListing: '/share_property',
  showSavedIndicator: '/saved_listings/show_indicator',
  validateEmailDomain: email => `/valid_email_domain?email=${email}`,

  // WP Blog
  featuredGuides: params => `/wp-json/oe/v1/guides/?${params}`,
  fetchGuide: townShortName => `/wp-json/oe/v1/guides/${townShortName}/`,
  fetchStories: params => `/wp-json/oe/v1/posts/?${params}`,

  // Api
  captcha: key => `/api/oe/captcha/${key}`,
  recentListings: '/api/oe/recent_listings.json',
  recentSearches: '/api/oe/recent_searches.json',
  searchPropertiesByText: text => `/api/oe/properties?term=${encodeURIComponent(text)}`,
  approveListing: '/api/oe/approve_listing',
  verifyListing: '/api/oe/verify_listing',
  denyListing: '/api/oe/decline_listing',
  internetNumberOptions: (listingClass, term) => (
    `/api/oe/internet_numbers?listing_class=${listingClass}&terms=${term}`
  ),
  professionalContactsAgents: text => `/api/oe/agents/professional_contacts?term=${encodeURIComponent(text)}`,
  professionalContactsCustomers: text => `/api/oe/customers/professional_contacts?term=${encodeURIComponent(text)}`,
  professionalContacts: text => `/manage/contacts?term=${encodeURIComponent(text)}`,
  duplicateListing: '/manage/duplicate_listing',
  markContactAsFavorite: contactId => `/api/oe/contacts/${contactId}/favorite`,
  signStaticGoogleMapUrl: url => `/signed_google_map_url?url=${encodeURIComponent(url)}`,
  manageContactNotes: '/manage/contact_notes',
  propertyPriceHistory: id => `/api/oe/property_price_history/${id}`,

  // Api users
  searchUsers: term => `/api/oe/users?term=${term}`,

  // Admin Professional
  createProfessional: '/admin/professionals',
  updateProfessional: id => `/admin/professionals/${id}`,
  updateProfessionalStatus: id => `/admin/professionals/${id}/status`,

  // Admin PropertyOwner
  propertyOwners: '/admin/property_owners',
  createPropertyOwner: '/admin/property_owners',
  updatePropertyOwner: id => `/admin/property_owners/${id}`,

  // Admin Manage Sale
  adminManageSale: id => `/admin/sales/${id}`,
  adminUpdateSoldSale: listingId => `/admin/sales/${listingId}/sold_attributes`,
  adminSalesCollection: (params = '') => `/admin/sales/${params}`,
  adminSaleImages: id => `/admin/sales/${id}/images`,
  adminPresignUpload: '/admin/uploads',
  adminSaleImage: (listingId, imageId) => `/admin/sales/${listingId}/images/${imageId}`,
  adminSaleImagesBulkUpdate: id => `/admin/sales/${id}/images/bulk_update`,
  adminSaleFloorplans: id => `/admin/sales/${id}/floorplans`,
  adminSaleFloorplan: (listingId, imageId) => `/admin/sales/${listingId}/floorplans/${imageId}`,
  adminSaleFloorplansBulkUpdate: id => `/admin/sales/${id}/floorplans/bulk_update`,
  adminSaleSurveys: id => `/admin/sales/${id}/surveys`,
  adminSaleSurvey: (listingId, imageId) => `/admin/sales/${listingId}/surveys/${imageId}`,
  adminSaleSurveysBulkUpdate: id => `/admin/sales/${id}/surveys/bulk_update`,
  adminArchiveSale: id => `/admin/sales/${id}/archive`,

  // Admin Manage Rental
  adminManageRental: id => `/admin/rentals/${id}`,
  adminRentalsCollection: (params = '') => `/admin/rentals/${params}`,
  adminRentalImages: id => `/admin/rentals/${id}/images`,
  adminRentalImage: (listingId, imageId) => `/admin/rentals/${listingId}/images/${imageId}`,
  adminRentalImagesBulkUpdate: id => `/admin/rentals/${id}/images/bulk_update`,
  adminRentalFloorplans: id => `/admin/rentals/${id}/floorplans`,
  adminRentalFloorplan: (listingId, imageId) => `/admin/rentals/${listingId}/floorplans/${imageId}`,
  adminRentalFloorplansBulkUpdate: id => `/admin/rentals/${id}/floorplans/bulk_update`,
  adminRentalSurveys: id => `/admin/rentals/${id}/surveys`,
  adminRentalSurvey: (listingId, imageId) => `/admin/rentals/${listingId}/surveys/${imageId}`,
  adminRentalSurveysBulkUpdate: id => `/admin/rentals/${id}/surveys/bulk_update`,
  adminArchiveRental: id => `/admin/rentals/${id}/archive`,

  // Admin Manage Land
  adminManageLand: id => `/admin/lands/${id}`,
  adminLandsCollection: (params = '') => `/admin/lands/${params}`,
  adminLandImages: id => `/admin/lands/${id}/images`,
  adminLandImage: (listingId, imageId) => `/admin/lands/${listingId}/images/${imageId}`,
  adminLandImagesBulkUpdate: id => `/admin/lands/${id}/images/bulk_update`,
  adminLandFloorplans: id => `/admin/lands/${id}/floorplans`,
  adminLandFloorplan: (listingId, imageId) => `/admin/lands/${listingId}/floorplans/${imageId}`,
  adminLandFloorplansBulkUpdate: id => `/admin/lands/${id}/floorplans/bulk_update`,
  adminLandSurveys: id => `/admin/lands/${id}/surveys`,
  adminLandSurvey: (listingId, imageId) => `/admin/lands/${listingId}/surveys/${imageId}`,
  adminLandSurveysBulkUpdate: id => `/admin/lands/${id}/surveys/bulk_update`,
  adminArchiveLand: id => `/admin/lands/${id}/archive`,

  // Admin Manage Property
  identifyProperty: '/admin/properties/identify',

  // Admin Dashboard
  adminProfessionalMessages: (params = '') => `/admin/messages/replied_professionals/${params}`,
  adminOwnerMessages: (params = '') => `/admin/messages/replied_owners/${params}`,

  // Manage Sale
  manageSale: id => `/manage/listings/sales/${id}`,
  salesCollection: (params = '') => `/manage/listings/sales${params}`,
  saleImages: id => `/manage/listings/sales/${id}/images`,
  saleImage: (listingId, imageId) => `/manage/listings/sales/${listingId}/images/${imageId}`,
  saleImagesBulkUpdate: id => `/manage/listings/sales/${id}/images/bulk_update`,
  saleFloorplans: id => `/manage/listings/sales/${id}/floorplans`,
  saleFloorplan: (listingId, imageId) => `/manage/listings/sales/${listingId}/floorplans/${imageId}`,
  saleFloorplansBulkUpdate: id => `/manage/listings/sales/${id}/floorplans/bulk_update`,
  saleSurveys: id => `/manage/listings/sales/${id}/surveys`,
  saleSurvey: (listingId, imageId) => `/manage/listings/sales/${listingId}/surveys/${imageId}`,
  saleSurveysBulkUpdate: id => `/manage/listings/sales/${id}/surveys/bulk_update`,
  presignUpload: '/manage/uploads',
  manageSaleSearch: '/manage/search/sales',
  manageSaleSearchNew: '/manage/search/sales/new',
  updateSoldSale: listingId => `/manage/listings/sales/${listingId}/sold_attributes`,
  archiveSale: id => `/manage/listings/sales/${id}/archive`,

  // Manage Owner's Properties
  ownerProperty: '/owners/my_properties',
  presignOwnershipDocumentUpload: '/owners/ownership_document_uploads',
  ownerSaleOwnershipDocuments: id => `/owners/listings/sales/${id}/ownership_documents`,
  ownerRentalOwnershipDocuments: id => `/owners/listings/rentals/${id}/ownership_documents`,
  ownerSaleOwnershipDocument: (id, attachmentId) => `/owners/listings/sales/${id}/ownership_documents/${attachmentId}`,
  ownerRentalOwnershipDocument: (id, attachmentId) => (
    `/owners/listings/rentals/${id}/ownership_documents/${attachmentId}`
  ),

  // Manage Owner's messages
  fetchOwnersMessages: (id, params = '') => `/owners/my_properties/${id}?${params}`,

  // Manage Owner's Sale
  ownersSale: id => `/owners/listings/sales/${id}`,
  ownersSaleCollection: (params = '') => `/owners/listings/sales/${params}`,
  ownerPresignUpload: '/owners/uploads',
  ownerSaleImages: id => `/owners/listings/sales/${id}/images`,
  ownerSaleImage: (listingId, imageId) => `/owners/listings/sales/${listingId}/images/${imageId}`,
  ownerSaleImagesBulkUpdate: id => `/owners/listings/sales/${id}/images/bulk_update`,
  ownerSaleFloorplans: id => `/owners/listings/sales/${id}/floorplans`,
  ownerSaleFloorplan: (listingId, imageId) => `/owners/listings/sales/${listingId}/floorplans/${imageId}`,
  ownerSaleFloorplansBulkUpdate: id => `/owners/listings/sales/${id}/floorplans/bulk_update`,
  ownerSaleSurveys: id => `/owners/listings/sales/${id}/surveys`,
  ownerSaleSurvey: (listingId, imageId) => `/owners/listings/sales/${listingId}/surveys/${imageId}`,
  ownerSaleSurveysBulkUpdate: id => `/owners/listings/sales/${id}/surveys/bulk_update`,

  // Manage Owner's Rental
  ownersRental: id => `/owners/listings/rentals/${id}`,
  ownersRentalCollection: (params = '') => `/owners/listings/rentals/${params}`,
  ownerRentalImages: id => `/owners/listings/rentals/${id}/images`,
  ownerRentalImage: (listingId, imageId) => `/owners/listings/rentals/${listingId}/images/${imageId}`,
  ownerRentalImagesBulkUpdate: id => `/owners/listings/rentals/${id}/images/bulk_update`,
  ownerRentalFloorplans: id => `/owners/listings/rentals/${id}/floorplans`,
  ownerRentalFloorplan: (listingId, imageId) => `/owners/listings/rentals/${listingId}/floorplans/${imageId}`,
  ownerRentalFloorplansBulkUpdate: id => `/owners/listings/rentals/${id}/floorplans/bulk_update`,
  ownerRentalSurveys: id => `/owners/listings/rentals/${id}/surveys`,
  ownerRentalSurvey: (listingId, imageId) => `/owners/listings/rentals/${listingId}/surveys/${imageId}`,
  ownerRentalSurveysBulkUpdate: id => `/owners/listings/rentals/${id}/surveys/bulk_update`,

  // Manage Rental
  manageRental: id => `/manage/listings/rentals/${id}`,
  rentalsCollection: (params = '') => `/manage/listings/rentals${params}`,
  rentalImages: id => `/manage/listings/rentals/${id}/images`,
  rentalImage: (listingId, imageId) => `/manage/listings/rentals/${listingId}/images/${imageId}`,
  rentalImagesBulkUpdate: id => `/manage/listings/rentals/${id}/images/bulk_update`,
  rentalFloorplans: id => `/manage/listings/rentals/${id}/floorplans`,
  rentalFloorplan: (listingId, imageId) => `/manage/listings/rentals/${listingId}/floorplans/${imageId}`,
  rentalFloorplansBulkUpdate: id => `/manage/listings/rentals/${id}/floorplans/bulk_update`,
  rentalSurveys: id => `/manage/listings/rentals/${id}/surveys`,
  rentalSurvey: (listingId, imageId) => `/manage/listings/rentals/${listingId}/surveys/${imageId}`,
  rentalSurveysBulkUpdate: id => `/manage/listings/rentals/${id}/surveys/bulk_update`,
  manageRentalSearch: '/manage/search/rentals',
  manageRentalSearchNew: '/manage/search/rentals/new',
  archiveRental: id => `/manage/listings/rentals/${id}/archive`,
  rentalPrices: id => `/manage/listings/rentals/${id}/prices`,
  rentalHistory: id => `/manage/listings/rentals/${id}/history`,

  // Manage Land
  manageLand: id => `/manage/listings/lands/${id}`,
  landsCollection: (params = '') => `/manage/listings/lands${params}`,
  landImages: id => `/manage/listings/lands/${id}/images`,
  landImage: (listingId, imageId) => `/manage/listings/lands/${listingId}/images/${imageId}`,
  landImagesBulkUpdate: id => `/manage/listings/lands/${id}/images/bulk_update`,
  landFloorplans: id => `/manage/listings/lands/${id}/floorplans`,
  landFloorplan: (listingId, imageId) => `/manage/listings/lands/${listingId}/floorplans/${imageId}`,
  landFloorplansBulkUpdate: id => `/manage/listings/lands/${id}/floorplans/bulk_update`,
  landSurveys: id => `/manage/listings/lands/${id}/surveys`,
  landSurvey: (listingId, imageId) => `/manage/listings/lands/${listingId}/surveys/${imageId}`,
  landSurveysBulkUpdate: id => `/manage/listings/lands/${id}/surveys/bulk_update`,
  manageLandSearch: '/manage/search/lands',
  manageLandSearchNew: '/manage/search/lands/new',
  archiveLand: id => `/manage/listings/lands/${id}/archive`,

  // Manage Listing Folder
  listingFolderNames: '/manage/folders/listing_folder_names.json',
  listingFoldersCollection: '/manage/folders/listings',
  createListingFolder: '/manage/folders/listings.json',
  updateListingFolder: id => `/manage/folders/listings/${id}`,
  archiveListingFolder: id => `/manage/folders/listings/${id}/archive`,
  restoreListingFolder: id => `/manage/folders/listings/${id}/restore`,
  updateListingsItems: id => `/manage/folders/listings/${id}/items`,
  loadMoreListingItems: (id, pageNumber) => `/manage/folders/listings/${id}.json?page=${pageNumber}`,
  loadListingItems: (id, params = '') => `/manage/folders/listings/${id}?${params}`,
  reorderListings: id => `/manage/folders/listings/${id}/items`,
  fetchListingFolders: (params = '') => `/manage/folders/listings?${params}`,
  fetchArchiveFolders: (params = '') => `/manage/folders/listings/archived?${params}`,
  assignContactToFolder: (folderId, contactId) => (
    `/manage/folders/folders_contacts?folder_id=${folderId}&contact_id=${contactId}`
  ),
  updateContactForFolder: id => `/manage/folders/folders_contacts/${id}`,
  shareListingFolder: id => `/manage/folders/listings/${id}/share`,
  folder: id => `/manage/folders/listings/${id}`,

  // Manage Search Folder
  searchFolder: '/manage/folders/searches.json',
  searchFolderNames: '/manage/folders/search_folder_names.json',
  updateSearchFolder: id => `/manage/folders/searches/${id}`,
  archiveSearchFolder: id => `/manage/folders/searches/${id}/archive`,
  restoreSearchFolder: id => `/manage/folders/searches/${id}/restore/`,
  updateSearchesItems: id => `/manage/folders/searches/${id}/items`,

  // Manage Share Listing
  manageShareListingsToCustomers: '/manage/share_listings_to_customers',
  manageShareListingsToAgents: '/manage/share_listings_to_agents',
  validateRecipientsEmailDomain: params => `/valid_email_domain/recipients?${params}`,

  // Mange Professional
  createManageProfessional: '/manage/professionals',
  createProfessionalPhoto: '/manage/professional_photos',
  assignProfessionalPhoto: id => `/manage/professional_photos/${id}/assign`,
  destroyProfessionalPhoto: id => `/manage/professional_photos/${id}`,
  updateManageProfessional: id => `/manage/professionals/${id}`,

  // Manage Brokerage
  updateBrokerage: '/manage/brokerage',
  assignBrokerageLogo: '/manage/brokerage_logos/assign',
  destroyBrokerageLogo: '/manage/brokerage_logos',

  // Manage Print Listings
  fetchPrintListings: data => `/manage/print_listings?${data}`,

  // Manage ImportantEdits
  fetchImportantEdits: (params = '') => `/manage/listings/important_edits?${params}`,

  // Manage Messsages
  fetchReceivedMessages: (params = '') => `/manage/messages/received?${params}`,
  fetchSharedMessages: (params = '') => `/manage/messages/shared?${params}`,
  trackPhoneCall: '/track_phone_call',

  // Manage Public Records
  fetchPublicRecords: (params = '') => `/manage/listings/public_records?${params}`,

  // Manage Pending Listings
  fetchPending: (params = '') => `/manage/listings/pending?${params}`,

  // Manage Needs Attention Listings
  fetchNeedsAttention: (type, params = '') => `/manage/needs_attention/${type}?${params}`,

  // Manage Basket
  fetchBasket: '/manage/folders/basket',
  showBasket: '/manage/folders/basket',
  basketItems: '/manage/folders/baskets/items',
  clearBasket: '/manage/folders/baskets/clear',

  // Manage Link Search To Contact
  fetchLinkSearchToContact: '/manage/link_search_to_contact',

  // Manage Hotsheets
  fetchSaleHotsheets: (params = '') => `/manage/hotsheets/sales?${params}`,
  fetchRentalHotsheets: (params = '') => `/manage/hotsheets/rentals?${params}`,
  fetchLandHotsheets: (params = '') => `/manage/hotsheets/lands?${params}`,
  searchHotsheets: '/manage/hotsheets/searches',

  // Manage Property Polygons
  fetchPropertyPolygons: (params = '') => `/property_polygons?${params}`
}
