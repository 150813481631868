import {
  withMiddleware,
  catchUnauthorizedAndCall,
  catchCsrfTokenErrorAndCall,
  parseJson,
  authenticationFailure,
  csrfTokenFailure,
  withResponseAndData,
  withRedirectURL,
  withPendingServiceCallOnError,
  withNotify
} from '../utils/apiMiddleware'
import captchaVerifiedApiCall, { captchaWarningMessage } from '../utils/captchaVerifiedApiCall'
import { get, post, put, del, upload, externalGet, externalUpload, apiCall } from '../utils/api'
import { serialize } from '../utils/common'
import routes from '../config/routes'
import { SAVE_SEARCH_CAPTCHA_KEY } from '../config/recaptcha'

const QuickSearch = {
  get: text => get(routes.autocompletes(text))
}

const RecentViews = {
  get: () => get(routes.recentListings)
}

const RecentSearches = {
  get: () => get(routes.recentSearches)
}

const Captcha = {
  show: captchaKey => get(routes.captcha(captchaKey))
}

const Listing = {
  updateSavedStatus: data => (
    withMiddleware(
      withPendingServiceCallOnError({
        name: ['transport', 'Listing', 'updateSavedStatusTask'],
        args: data
      }, { status: 401 }),
      catchUnauthorizedAndCall(authenticationFailure(routes.authPath)),
      parseJson,
      withNotify({ name: ['transport', 'Listing', 'updateSavedStatus'] })
    )(captchaVerifiedApiCall({ message: captchaWarningMessage, transport: { Captcha } }))(data)
  ),
  updateSavedStatusTask: data => (
    withMiddleware(
      withPendingServiceCallOnError({
        name: ['transport', 'Listing', 'updateSavedStatusTask'],
        args: data
      }, { status: 401 }),
      parseJson
    )(captchaVerifiedApiCall({ message: captchaWarningMessage, transport: { Captcha } }))(data)
  ),
  sendContactAgent: data => post(routes.contactAgent, data),
  sendContactOwner: data => post(routes.contactOwner, data),
  sendSharedMessage: data => post(routes.shareListing, data),
  getHistoricalData: (id) => get(routes.propertyPriceHistory(id))
}

const SavedListings = {
  showIndicator: () => get(routes.showSavedIndicator)
}

const User = {
  signIn: user => withMiddleware(withResponseAndData, withRedirectURL)(apiCall)(
    routes.authPath, { method: 'POST', body: JSON.stringify(user) }
  ),
  signUp: user => withMiddleware(withResponseAndData, withRedirectURL)(apiCall)(
    routes.signUp, { method: 'POST', body: JSON.stringify(user) }
  ),
  signOut: () => apiCall(routes.signOut, { method: 'DELETE'}),
  resetPassword: user => post(routes.resetPassword, user),
  editPassword: user => put(routes.resetPassword, user),
  setPassword: user => put(routes.setPassword, user),
  edit: user => put(routes.editUser, user),
  validateEmailDomain: email => get(routes.validateEmailDomain(email))
}

const Search = {
  submit: (url, data) => post(url, data),
  save: data => withMiddleware(
    catchCsrfTokenErrorAndCall(csrfTokenFailure),
    withPendingServiceCallOnError({
      name: ['transport', 'Search', 'saveTask'],
      args: data
    }, { status: 401 }),
    catchUnauthorizedAndCall(authenticationFailure(routes.authPath)),
    parseJson
  )(captchaVerifiedApiCall({ message: captchaWarningMessage, transport: { Captcha } }))(
    { url: routes.saveSearch, method: 'POST', body: data, captchaKey: SAVE_SEARCH_CAPTCHA_KEY }
  ),
  saveTask: data => withMiddleware(
    withPendingServiceCallOnError({
      name: ['transport', 'Search', 'saveTask'],
      args: data
    }, { status: 401 }),
    parseJson
  )(captchaVerifiedApiCall({ message: captchaWarningMessage, transport: { Captcha } }))(
    { url: routes.saveSearch, method: 'POST', body: data, captchaKey: SAVE_SEARCH_CAPTCHA_KEY }
  ),
  destroy: id => withMiddleware(
    catchUnauthorizedAndCall(authenticationFailure(routes.authPath)),
    parseJson
  )(captchaVerifiedApiCall({ message: captchaWarningMessage, transport: { Captcha } }))(
    { url: routes.destroySearch(id), method: 'DELETE', captchaKey: SAVE_SEARCH_CAPTCHA_KEY }
  ),
  getSearchInfo: (url, data) => post(url, data),
  internetNumberOptions: (listingClass, term) => get(routes.internetNumberOptions(listingClass, term)),
  linkSearchToContact: data => post(routes.fetchLinkSearchToContact, data)
}

const Subscription = {
  toggle: (url, method, data) => withMiddleware(
    catchUnauthorizedAndCall(authenticationFailure(routes.authPath)),
    parseJson
  )(apiCall)(
    url, { method: method, body: JSON.stringify(data) }
  )
}

const Agent = {
  sendMessage: genericContactForm => post(routes.genericContactAgent, { genericContactForm })
}

const Guides = {
  getCollection: (host, params) => externalGet(host + routes.featuredGuides(serialize(params))),
  get: (host, townShortName) => externalGet(host + routes.fetchGuide(townShortName))
}

const Stories = {
  getCollection: (host, params) => externalGet(host + routes.fetchStories(serialize(params)))
}

const ManageSale = {
  update: (id, data) => put(routes.manageSale(id), data),
  create: data => post(routes.salesCollection(), data),
  updateSoldSale: (id, data) => put(routes.updateSoldSale(id), data)
}

const AdminManageSale = {
  update: (id, data) => put(routes.adminManageSale(id), data),
  updateSoldSale: (id, data) => put(routes.adminUpdateSoldSale(id), data)
}

const AdminManageRental = {
  update: (id, data) => put(routes.adminManageRental(id), data)
}

const AdminManageLand = {
  update: (id, data) => put(routes.adminManageLand(id), data)
}

const ManageRental = {
  update: (id, data) => put(routes.manageRental(id), data),
  updatePrice: (id, data) => put(routes.rentalPrices(id), data),
  create: data => post(routes.rentalsCollection(), data)
}

const ManageLand = {
  update: (id, data) => put(routes.manageLand(id), data),
  create: data => post(routes.landsCollection(), data)
}

const ManageListings = {
  getSales: filters => get(routes.salesCollection(`?${serialize(filters)}`)),
  getRentals: filters => get(routes.rentalsCollection(`?${serialize(filters)}`)),
  getLands: filters => get(routes.landsCollection(`?${serialize(filters)}`)),
  delete: url => del(url),
  approve: data => put(routes.approveListing, { approveListing: data }),
  verify: data => put(routes.verifyListing, { verifyListing: data }),
  deny: data => put(routes.denyListing, { declineListing: data }),
  duplicateListing: data => post(routes.duplicateListing, { duplicateListing: data }),
  archiveSale: id => post(routes.archiveSale(id)),
  archiveRental: id => post(routes.archiveRental(id)),
  archiveLand: id => post(routes.archiveLand(id))
}

const AdminManageListings = {
  archiveSale: id => post(routes.adminArchiveSale(id)),
  archiveRental: id => post(routes.adminArchiveRental(id)),
  archiveLand: id => post(routes.adminArchiveLand(id))
}

const OwnershipDocuments = {
  presignUpload: fileName => post(routes.presignOwnershipDocumentUpload, { fileName }),
  uploadToS3: (...args) => externalUpload(...args),
  uploadForSale: (id, fileData) => upload(routes.ownerSaleOwnershipDocuments(id), fileData),
  uploadForRental: (id, fileData) => upload(routes.ownerRentalOwnershipDocuments(id), fileData),
  deleteForSale: (id, attachmentId) => del(routes.ownerSaleOwnershipDocument(id, attachmentId)),
  deleteForRental: (id, attachmentId) => del(routes.ownerRentalOwnershipDocument(id, attachmentId))
}

const ImageManager = {
  presignUpload: fileName => post(routes.presignUpload, { fileName }),
  uploadToS3: (...args) => externalUpload(...args),
  uploadForSale: (id, imageData) => upload(routes.saleImages(id), imageData),
  deleteForSale: (listingId, imageId) => del(routes.saleImage(listingId, imageId)),
  imgStatusForSale: id => get(routes.saleImages(id)),

  uploadForRental: (id, imageData) => upload(routes.rentalImages(id), imageData),
  deleteForRental: (listingId, imageId) => del(routes.rentalImage(listingId, imageId)),
  imgStatusForRental: id => get(routes.rentalImages(id)),

  uploadForLand: (id, imageData) => upload(routes.landImages(id), imageData),
  deleteForLand: (listingId, imageId) => del(routes.landImage(listingId, imageId)),
  imgStatusForLand: id => get(routes.landImages(id)),

  ownerPresignUpload: fileName => post(routes.ownerPresignUpload, { fileName }),
  ownerUploadForSale: (id, imageData) => upload(routes.ownerSaleImages(id), imageData),
  ownerDeleteForSale: (listingId, imageId) => del(routes.ownerSaleImage(listingId, imageId)),
  ownerImgStatusForSale: id => get(routes.ownerSaleImages(id)),

  ownerUploadForRental: (id, imageData) => upload(routes.ownerRentalImages(id), imageData),
  ownerDeleteForRental: (listingId, imageId) => del(routes.ownerRentalImage(listingId, imageId)),
  ownerImgStatusForRental: id => get(routes.ownerRentalImages(id)),

  bulkUpdate: {
    sales: (id, images) => post(routes.saleImagesBulkUpdate(id), images),
    rentals: (id, images) => post(routes.rentalImagesBulkUpdate(id), images),
    lands: (id, images) => post(routes.landImagesBulkUpdate(id), images)
  },
  ownerBulkUpdate: {
    sales: (id, images) => post(routes.ownerSaleImagesBulkUpdate(id), images),
    rentals: (id, images) => post(routes.ownerRentalImagesBulkUpdate(id), images)
  },
  adminPresignUpload: fileName => post(routes.adminPresignUpload, { fileName }),
  adminUploadForSale: (id, imageData) => upload(routes.adminSaleImages(id), imageData),
  adminImgStatusForSale: id => get(routes.adminSaleImages(id)),

  adminDeleteForSale: (listingId, imageId) => del(routes.adminSaleImage(listingId, imageId)),
  adminUploadForRental: (id, imageData) => upload(routes.adminRentalImages(id), imageData),
  adminImgStatusForRental: id => get(routes.adminRentalImages(id)),

  adminDeleteForRental: (listingId, imageId) => del(routes.adminRentalImage(listingId, imageId)),
  adminUploadForLand: (id, imageData) => upload(routes.adminLandImages(id), imageData),
  adminImgStatusForLand: id => get(routes.adminLandImages(id)),

  adminDeleteForLand: (listingId, imageId) => del(routes.adminLandImage(listingId, imageId)),
  adminBulkUpdate: {
    sales: (id, images) => post(routes.adminSaleImagesBulkUpdate(id), images),
    rentals: (id, images) => post(routes.adminRentalImagesBulkUpdate(id), images),
    lands: (id, images) => post(routes.adminLandImagesBulkUpdate(id), images)
  }
}

const FloorplanManager = {
  uploadForSale: (id, imageData) => upload(routes.saleFloorplans(id), imageData),
  deleteForSale: (listingId, imageId) => del(routes.saleFloorplan(listingId, imageId)),
  imgStatusForSale: id => get(routes.saleFloorplans(id)),

  uploadForRental: (id, imageData) => upload(routes.rentalFloorplans(id), imageData),
  deleteForRental: (listingId, imageId) => del(routes.rentalFloorplan(listingId, imageId)),
  imgStatusForRental: id => get(routes.rentalFloorplans(id)),

  uploadForLand: (id, imageData) => upload(routes.landFloorplans(id), imageData),
  deleteForLand: (listingId, imageId) => del(routes.landFloorplan(listingId, imageId)),
  imgStatusForLand: id => get(routes.landFloorplans(id)),

  ownerUploadForSale: (id, imageData) => upload(routes.ownerSaleFloorplans(id), imageData),
  ownerDeleteForSale: (listingId, imageId) => del(routes.ownerSaleFloorplan(listingId, imageId)),
  ownerImgStatusForSale: id => get(routes.ownerSaleFloorplans(id)),

  ownerUploadForRental: (id, imageData) => upload(routes.ownerRentalFloorplans(id), imageData),
  ownerDeleteForRental: (listingId, imageId) => del(routes.ownerRentalFloorplan(listingId, imageId)),
  ownerImgStatusForRental: id => get(routes.ownerRentalFloorplans(id)),

  bulkUpdate: {
    sales: (id, images) => post(routes.saleFloorplansBulkUpdate(id), images),
    rentals: (id, images) => post(routes.rentalFloorplansBulkUpdate(id), images),
    lands: (id, images) => post(routes.landFloorplansBulkUpdate(id), images)
  },
  ownerBulkUpdate: {
    sales: (id, images) => post(routes.ownerSaleFloorplansBulkUpdate(id), images),
    rentals: (id, images) => post(routes.ownerRentalFloorplansBulkUpdate(id), images)
  },
  adminUploadForSale: (id, imageData) => upload(routes.adminSaleFloorplans(id), imageData),
  adminDeleteForSale: (listingId, imageId) => del(routes.adminSaleFloorplan(listingId, imageId)),
  adminImgStatusForSale: id => get(routes.adminSaleFloorplans(id)),

  adminUploadForRental: (id, imageData) => upload(routes.adminRentalFloorplans(id), imageData),
  adminDeleteForRental: (listingId, imageId) => del(routes.adminRentalFloorplan(listingId, imageId)),
  adminImgStatusForRental: id => get(routes.adminRentalFloorplans(id)),

  adminUploadForLand: (id, imageData) => upload(routes.adminLandFloorplans(id), imageData),
  adminDeleteForLand: (listingId, imageId) => del(routes.adminLandFloorplan(listingId, imageId)),
  adminImgStatusForLand: id => get(routes.adminLandFloorplans(id)),

  adminBulkUpdate: {
    sales: (id, images) => post(routes.adminSaleFloorplansBulkUpdate(id), images),
    rentals: (id, images) => post(routes.adminRentalFloorplansBulkUpdate(id), images),
    lands: (id, images) => post(routes.adminLandFloorplansBulkUpdate(id), images)
  }
}

const SurveyManager = {
  uploadForSale: (id, imageData) => upload(routes.saleSurveys(id), imageData),
  deleteForSale: (listingId, imageId) => del(routes.saleSurvey(listingId, imageId)),
  imgStatusForSale: id => get(routes.saleSurveys(id)),

  uploadForRental: (id, imageData) => upload(routes.rentalSurveys(id), imageData),
  deleteForRental: (listingId, imageId) => del(routes.rentalSurvey(listingId, imageId)),
  imgStatusForRental: id => get(routes.rentalSurveys(id)),

  uploadForLand: (id, imageData) => upload(routes.landSurveys(id), imageData),
  deleteForLand: (listingId, imageId) => del(routes.landSurvey(listingId, imageId)),
  imgStatusForLand: id => get(routes.landSurveys(id)),

  ownerUploadForSale: (id, imageData) => upload(routes.ownerSaleSurveys(id), imageData),
  ownerDeleteForSale: (listingId, imageId) => del(routes.ownerSaleSurvey(listingId, imageId)),
  ownerImgStatusForSale: id => get(routes.ownerSaleSurveys(id)),

  ownerUploadForRental: (id, imageData) => upload(routes.ownerRentalSurveys(id), imageData),
  ownerDeleteForRental: (listingId, imageId) => del(routes.ownerRentalSurvey(listingId, imageId)),
  ownerImgStatusForRental: id => get(routes.ownerRentalSurveys(id)),

  bulkUpdate: {
    sales: (id, images) => post(routes.saleSurveysBulkUpdate(id), images),
    rentals: (id, images) => post(routes.rentalSurveysBulkUpdate(id), images),
    lands: (id, images) => post(routes.landSurveysBulkUpdate(id), images)
  },
  ownerBulkUpdate: {
    sales: (id, images) => post(routes.ownerSaleSurveysBulkUpdate(id), images),
    rentals: (id, images) => post(routes.ownerRentalSurveysBulkUpdate(id), images)
  },
  adminUploadForSale: (id, imageData) => upload(routes.adminSaleSurveys(id), imageData),
  adminDeleteForSale: (listingId, imageId) => del(routes.adminSaleSurvey(listingId, imageId)),
  adminImgStatusForSale: id => get(routes.adminSaleSurveys(id)),

  adminUploadForRental: (id, imageData) => upload(routes.adminRentalSurveys(id), imageData),
  adminDeleteForRental: (listingId, imageId) => del(routes.adminRentalSurvey(listingId, imageId)),
  adminImgStatusForRental: id => get(routes.adminRentalSurveys(id)),

  adminUploadForLand: (id, imageData) => upload(routes.adminLandSurveys(id), imageData),
  adminDeleteForLand: (listingId, imageId) => del(routes.adminLandSurvey(listingId, imageId)),
  adminImgStatusForLand: id => get(routes.adminLandSurveys(id)),

  adminBulkUpdate: {
    sales: (id, images) => post(routes.adminSaleSurveysBulkUpdate(id), images),
    rentals: (id, images) => post(routes.adminRentalSurveysBulkUpdate(id), images),
    lands: (id, images) => post(routes.adminLandSurveysBulkUpdate(id), images)
  }
}

const Professional = {
  create: data => post(routes.createProfessional, data),
  update: (id, data) => put(routes.updateProfessional(id), data),
  uploadImage: (id, file) => upload(routes.createProfessionalPhoto, { id, 'professional[photo]': file }),
  assignImage: (id, file) => upload(routes.assignProfessionalPhoto(id), { 'professional[photo]': file }),
  deleteImage: id => del(routes.destroyProfessionalPhoto(id)),
  updateStatus: (id, data) => put(routes.updateProfessionalStatus(id), data),
  getContactsAgents: text => get(routes.professionalContactsAgents(text)),
  getContactsCustomers: text => get(routes.professionalContactsCustomers(text)),
  searchContacts: text => get(routes.professionalContacts(text)),
  validateRecipientsEmailDomain: recipients => get(routes.validateRecipientsEmailDomain(`${serialize(recipients)}`))
}

const Properties = {
  getByText: text => get(routes.searchPropertiesByText(text))
}

const PropertyOwner = {
  create: data => post(routes.createPropertyOwner, data),
  update: (id, data) => put(routes.updatePropertyOwner(id), data)
}

const Folders = {
  getListingFolderNames: () => get(routes.listingFolderNames),
  getSearchFolderNames: () => get(routes.searchFolderNames),
  getSearchFolders: () => get(routes.searchFolder),
  getFilteredListingFolders: filters => get(routes.fetchListingFolders(`${serialize(filters)}`)),
  getFilteredArchiveFolders: filters => get(routes.fetchArchiveFolders(`${serialize(filters)}`)),
  createListingFolder: data => post(routes.createListingFolder, data),
  createSearchFolder: data => post(routes.searchFolder, data),
  archiveListingFolder: folderId => post(routes.archiveListingFolder(folderId)),
  archiveSearchFolder: folderId => post(routes.archiveSearchFolder(folderId)),
  restoreListingFolder: folderId => post(routes.restoreListingFolder(folderId)),
  restoreSearchFolder: folderId => post(routes.restoreSearchFolder(folderId)),
  updateListingsItems: (folderId, data) => post(routes.updateListingsItems(folderId), data),
  updateSearchesItems: (folderId, data) => post(routes.updateSearchesItems(folderId), data),
  removeListingItemFolder: (folderId, data) => del(routes.updateListingsItems(folderId), data),
  removeSearchItemFolder: (folderId, data) => del(routes.updateSearchesItems(folderId), data),
  updateCollapsedStatus: (path, data) => put(path, data),
  updateListingFolder: (folderId, data) => put(routes.updateListingFolder(folderId), data),
  updateSearchFolder: (folderId, data) => put(routes.updateSearchFolder(folderId), data),
  loadMoreListingItems: (folderId, pageNumber) => get(routes.loadMoreListingItems(folderId, pageNumber)),
  loadListingItems: folderId => filters => get(routes.loadListingItems(folderId, `${serialize(filters)}`)),
  reorderListings: (folderId, data) => put(routes.reorderListings(folderId), data),
  assignContactToFolder: (folderId, contactId) => post(routes.assignContactToFolder(folderId, contactId)),
  updateContactForFolder: (id, data) => put(routes.updateContactForFolder(id), data),
  shareListingFolder: (folderId, data) => post(routes.shareListingFolder(folderId), data)
}

const ManageSearch = {
  sale: data => post(routes.manageSaleSearch, data),
  rental: data => post(routes.manageRentalSearch, data),
  land: data => post(routes.manageLandSearch, data),
  getCollection: url => get(url)
}

const ManageShare = {
  listingsToCustomers: data => post(routes.manageShareListingsToCustomers, data),
  listingsToAgents: data => post(routes.manageShareListingsToAgents, data)
}

const ManageOffice = {
  save: ({ links, data }) => (
    links.save.method === 'post' ?
      post(links.save.url, data) :
      put(links.save.url, data)
  )
}

const ManageProfessional = {
  create: data => post(routes.createManageProfessional, data),
  update: (id, data) => put(routes.updateManageProfessional(id), data)
}

const ManageBrokerage = {
  update: data => put(routes.updateBrokerage, data),
  assignImage: file => upload(routes.assignBrokerageLogo, { 'brokerage[logo]': file }),
  deleteImage: () => del(routes.destroyBrokerageLogo)
}

const ManagePrintListings = {
  get: data => get(routes.fetchPrintListings(serialize(data)))
}

const ManageImportantEdits = {
  updateStatus: url => apiCall(url, { method: 'post' }),
  getImportantEdits: filters => get(routes.fetchImportantEdits(`${serialize(filters)}`))
}

const ManageReceivedMessages = {
  getReceivedMessages: filters => get(routes.fetchReceivedMessages(`${serialize(filters)}`)),
  trackPhoneCall: data => post(routes.trackPhoneCall, data)
}

const ManageSharedMessages = {
  getSharedMessages: filters => get(routes.fetchSharedMessages(`${serialize(filters)}`))
}

const ManagePublicRecords = {
  getPublicRecords: filters => get(routes.fetchPublicRecords(`${serialize(filters)}`))
}

const ManagePendingListings = {
  getCollection: filters => get(routes.fetchPending(`${serialize(filters)}`))
}

const ManageNeedsAttention = {
  getCollection: listingClass => filters => get(routes.fetchNeedsAttention(listingClass, `${serialize(filters)}`))
}

const ManageBasket = {
  fetchBasket: () => get(routes.fetchBasket),
  addToBasket: data => post(routes.basketItems, data),
  removeFromBasket: data => del(routes.basketItems, data),
  clearBasket: () => del(routes.clearBasket)
}

const Users = {
  searchUsers: term => get(routes.searchUsers(term))
}

const ManageOwnersSale = {
  update: (id, data) => put(routes.ownersSale(id), data),
  create: data => post(routes.ownersSaleCollection(), data)
}

const ManageOwnersRental = {
  update: (id, data) => put(routes.ownersRental(id), data),
  create: data => post(routes.ownersRentalCollection(), data)
}

const ManageOwnersMessages = {
  getMessages: (id, filters) => get(routes.fetchOwnersMessages(id, `${serialize(filters)}`))
}

const ManageContacts = {
  addContactToFavorite: id => post(routes.markContactAsFavorite(id)),
  removeContactFromFavorite: id => del(routes.markContactAsFavorite(id))
}

const GoogleMap = {
  signStaticMaPUrl: url => post(routes.signStaticGoogleMapUrl(url))
}

const MaloneGeocoder = {
  identifyProperty: data => post(routes.identifyProperty, data)
}

const ManageHotsheets = {
  search: data => post(routes.searchHotsheets, data)
}

const PropertyPolygons = {
  get: data => get(routes.fetchPropertyPolygons(`${serialize(data)}`))
}

export default {
  QuickSearch,
  RecentViews,
  RecentSearches,
  Listing,
  SavedListings,
  User,
  Search,
  Subscription,
  Captcha,
  Agent,
  Guides,
  Stories,
  ManageSale,
  AdminManageSale,
  AdminManageListings,
  ManageRental,
  AdminManageRental,
  AdminManageLand,
  ManageLand,
  ManageListings,
  ImageManager,
  FloorplanManager,
  SurveyManager,
  Professional,
  Properties,
  PropertyOwner,
  Folders,
  ManageSearch,
  ManageShare,
  ManageOffice,
  ManageProfessional,
  ManageBrokerage,
  ManagePrintListings,
  ManageImportantEdits,
  ManageReceivedMessages,
  ManageSharedMessages,
  ManagePublicRecords,
  ManagePendingListings,
  ManageNeedsAttention,
  ManageBasket,
  Users,
  ManageOwnersSale,
  ManageOwnersRental,
  ManageOwnersMessages,
  ManageContacts,
  GoogleMap,
  MaloneGeocoder,
  ManageHotsheets,
  OwnershipDocuments,
  PropertyPolygons
}
